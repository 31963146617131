import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import BodyStyle from '../components/BodyStyle';

const TxtStyle = styled.div`
    display:flex;
    flex-direction: column;

    .section {
        .section-title {
            margin-bottom: 75px;
        }
        
        margin-bottom: 90px;

        .section-item {
            margin-bottom: 8px;
        }

        .separator {
            margin-right: 10px;
            margin-left: 10px;
        }
    }
`

const Txt = (props) => {
    const txt = useSelector(state => state.txt);

    const storytellingDOM = (<div className="section">
                        <div className="section-title">stories told</div>
                        <div className="section-content">
                            {txt.storytelling.map((t,i)=>{
                              return   (<div id={`${t}-${i}`} className="section-item">
                                            <a href={t.link} target="_blank" rel="noreferrer noopener">
                                                <span>
                                                    {`${t.title} // ${t.organization} // ${t.date}`}
                                                </span>
                                            </a>
                                        </div>);
                            })}
                        </div>
                    </div> );

    const writingDOM = (<div className="section">
                            <div className="section-title">stories written</div>
                            <div className="section-content">
                                {txt.writing.map((t,i)=>{
                                return   (<div id={`${t}-${i}`} className="section-item">
                                                <a href={t.link} target="_blank" rel="noreferrer noopener">
                                                <span>
                                                    {`${t.title} // ${t.organization} // ${t.date}`}
                                                </span>
                                            </a>
                                            </div>);
                                })}
                            </div>
                        </div> );
    return <BodyStyle>
        <TxtStyle>
            {storytellingDOM}
            {txt.writing.length > 0 ? writingDOM : <div></div>}
        </TxtStyle>
    </BodyStyle>
};

export default Txt;