import styled from 'styled-components';
import bg from './assets/img/bg.jpg';
import size from './responsive';

export default styled.div`
  overflow: auto;
  box-sizing: border-box;
  background-image: url(${bg});
  background-size: 1000px 562px;
  height: 100vh;
  width: 100vw;
  
  @media screen and ${size.horizontal} {
    display: flex;
    justify-content: center;
  }
  @media screen and ${size.vertical} {
    padding-left: 60px;
    padding-right: 60px;
  }

  * {
    box-sizing: border-box;
    font-size: 20px;
  }

  .content {
      display: flex;
      position: relative;
      height: fit-content;
      
      @media screen and ${size.horizontal} {
        top: 25%;
      }

    
      @media screen and ${size.vertical} {
        flex-direction: column;
        top: 10%;
      }
  }

  a {
      color: inherit;
      text-decoration: none;
  }

  a:hover {
      text-decoration: underline;
  }
`;