import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import size from '../../responsive';

import headshot from '../../assets/img/apang_headshot.jpeg';

import BodyStyle from '../components/BodyStyle';

const AboutStyle = styled.div`
    display:flex;
    margin-bottom: 50px;

    @media screen and ${size.horizontal} {
        justify-content: center;

        .name {
            margin-bottom: 75px;
        }

        .vertical {
            display: none;
        }

        .info {
            margin-left: 150px;
        }

        .headshot {
            width: 35%;
            height: auto;
            
        }
    }
    @media screen and ${size.vertical} {
        flex-direction: column;

        .name {
            margin-bottom: 30px;
        }

        .horizontal {
            display: none;
        }

        .info {
            margin-top: 30px;
        }

        .headshot {
            width: 100%;
            height: auto;
            
        }
    }

`

const About = () => {
    const about = useSelector(state => state.about);
    
    return <BodyStyle>
        <AboutStyle>
            <div className="name vertical">{about.name}</div>
            <img className="headshot" src={headshot} alt="headshot" />
            <div className="info">
                <div className="name horizontal">{about.name}</div>
                <div className="description">{about.description}</div>
            </div>
        </AboutStyle>
    </BodyStyle>
};

export default About;