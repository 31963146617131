import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";


import GlobalStyles from './GlobalStyles';
import Nav from './features/components/Nav';
import About from './features/containers/About';
import Txt from './features/containers/Txt';
import JS from './features/containers/Js';
import Stuff from './features/containers/Stuff';
import Connect from './features/containers/Connect';

function App() {
  return (
    <GlobalStyles>
      <Router>
        <div className="content">
          <Nav highlighted="default"/>
          <Switch>
            <Route exact path="/">
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/txt">
              <Txt />
            </Route>
            <Route path="/js">
              <JS />
            </Route>
            <Route path="/stuff">
              <Stuff />
            </Route>
            <Route path="/connect">
              <Connect />
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
      </Router>
    </GlobalStyles>
  );
}

export default App;
