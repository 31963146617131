import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import size from '../../responsive';

import BodyStyle from '../components/BodyStyle';

const JSStyle = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;

    .section {
        .section-title {
            margin-bottom: 75px;
        }
        margin-bottom: 30px;

        .project-images {
            display: flex;
            width:100%;

            .project-image {
                width: 50%;
                min-width: 425px;
                margin-right: 20px;

                img {
                    width: 100%;
                }
            }
        }
    }
    
    @media screen and ${size.vertical} {
        .horizontal {
            display: none;
        }
    }
`

const JS = () => {
    const js = useSelector(state => state.js);

    const jsDOM = js.map((p, i)=>{
        return (<div className="section-content">
                    <div className="project-name">{p.title}</div>
                    <div className="project-images">
                        <div className="project-image">
                            <img alt='js-1' src={p.images[0]}></img>
                        </div>
                        <div className="project-image horizontal">
                            <img alt='js-2' src={p.images[1]}></img>
                        </div>
                    </div>
                </div>)
    });
    return <BodyStyle>
        <JSStyle>
            <div className="section">
                <div className="section-title">projects</div>
                {jsDOM}
            </div> 
        </JSStyle>
    </BodyStyle>
};

export default JS;