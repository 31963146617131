import { createReducer } from '@reduxjs/toolkit'


import oily1 from '../../assets/img/projects/oily1.png';
import oily2 from '../../assets/img/projects/oily2.png';
import tli1 from '../../assets/img/projects/tli1.png';
import tli2 from '../../assets/img/projects/tli2.png';
import red1 from '../../assets/img/projects/redline1.png';
import red2 from '../../assets/img/projects/redline2.png';

const initialState = {
    about: {
        name: "aaron zeng pang",
        description: "An Oakland native, Aaron Pang is just a guy telling stories to any one who will listen. A first generation child of Chinese immigrants, a choir boy, a tech guy, and a disabled person, Aaron hopes his stories will inspire others to embrace their multi-faceted identities."
    },
    txt: {
        storytelling: [{
            title: "dumplings in iowa",
            organization: "Proof by America's Test Kitchen",
            date: "12/16/2021",
            link: "https://open.spotify.com/episode/0p5xxeUxUdOhRJCDIOLAXC?si=98af0fa7fd864547"
        },{
            title: "strangers",
            organization: "Bodies of Stories",
            date: "05/30/2020",
            link: "https://www.youtube.com/watch?v=hvlaDAJXZAI"
        }, {
            title: "balancing act",
            organization: "The Moth",
            date: "10/17/2019",
            link: "https://themoth.org/storytellers/aaron-pang"
        }, {
            title: "money well spent",
            organization: "Slant'd",
            date: "03/2019",
            link: "https://youtu.be/GF0YUINqI6I"
        }, {
            title: "smoke signals",
            organization: "Unruly Storyfest",
            date: "09/28/2015",
            link: "https://youtu.be/GF0YUINqI6I"
        }],
        writing: [{
            title: "code",
            organization: "Essay Daily",
            date: "12/30/2021",
            link: "https://www.essaydaily.org/2021/12/the-2021-christmas-octave-aaron-pang.html"
        }]
    },
    js: [{
        title: "redlining in oakland",
        images: [
            red1, 
            red2,
        ]
    }, {
        title: "oil and gas monitor",
        images: [
            oily1, 
            oily2,
        ]
    }, {
        title: "brand sentiment analyzer",
        images: [
            tli1,
            tli2
        ]
    }],
    stuff: [{
        title: "mando clothing co.",
        subtitle: "celebrating the intersections of cultures",
        link: "https://www.mandoclothing.com/"
    }, {
        title: "tortuga sf",
        subtitle: "mediterrean supper club",
        link: "https://www.instagram.com/tortugasf/"
    }],
    connect: [
        {
            title: 'twitter',
            link: 'https://twitter.com/AaronZengPang',
        },
        {
            title: 'instagram',
            link: 'https://www.instagram.com/az.pang/',
        }
    ]
};

const appReducer = createReducer(initialState, (builder) => { });

export default appReducer;