import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import BodyStyle from '../components/BodyStyle';

const Style = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;

    .section {
        margin-bottom: 30px;

        .section-subtitle {
            font-size: 20px;
        }
    }
`

const Stuff = (props) => {
    const stuff = useSelector(state => state.stuff);

    const stuffDOM = stuff.map((m, i)=>{
        return (<div id={`${m}-${i}`} className="section">
                    <a href={m.link} target="_blank" rel="noreferrer noopener" className="section-title">{m.title}</a>
                    <div className="section-subtitle">{m.subtitle}</div>
                </div> )
    });
    return <BodyStyle>
        <Style>
            {stuffDOM}
        </Style>
    </BodyStyle>
};

export default Stuff;