import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import BodyStyle from '../components/BodyStyle';

const Style = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;

    .section {
        margin-bottom: 30px;

        .section-subtitle {
            font-size: 20px;
        }
    }
`

const Connect = (props) => {
    const connect = useSelector(state => state.connect);

    const connectDOM = connect.map((m)=>{
        return (<div className="section">
                    <a href={m.link} target="_blank" rel="noreferrer noopener" className="section-title">{m.title}</a>
                </div> )
    });
    return <BodyStyle>
        <Style>
            {connectDOM}
        </Style>
    </BodyStyle>
};

export default Connect;