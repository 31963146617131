import React from 'react';
import styled, {keyframes} from 'styled-components';
import {
    Link,
    useLocation
} from "react-router-dom";

const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`

const Style = styled.div`
    display: flex;
    animation: ${fadeIn} .5s linear;

    .left {
        margin-right: 10px;
    }

    .not-highlighted {
        opacity: 40%;
    }

    .hide {
        opacity: 0%;
    }
`;

const Nav = ({highlighted}) => {
    const items = ['about', 'txt', 'js', 'stuff', 'connect'];
    const location = useLocation();
    const high = location.pathname.substring(1) || highlighted;

    return <Style>
        <div className="left">
            {items.map((item, i)=>{
                let lHigh = high;
                if(highlighted==='default') {
                    lHigh = 'about';
                }
                const showClass = lHigh===item ? '' : 'hide';
                return <div id={`${item}-${i}`} className={showClass}>azpang</div>;
            })}
        </div>
        <div className="right">
            {items.map((item, i)=>{
                const highlightClass = high===item || high === 'default' ? '' : 'not-highlighted'
                return <div id={`${item}-${i}`} className={highlightClass}><Link to={`/${item}`}>.{item}</Link></div>;
            })}
        </div>
    </Style>
};

export default Nav;