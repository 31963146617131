import styled, {keyframes} from 'styled-components';

import size from '../../responsive';

const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`

export default styled.div`
    display: flex;

    @media screen and ${size.horizontal} {
        margin-left: 15%;
        width: 850px;
    }

    @media screen and ${size.vertical} {
        margin-top: 60px;
        width: 100%;
    }
    
    animation: ${fadeIn} .5s linear;
`